@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:focus,
*:hover,
button,
button:focus,
button:hover {
    outline: none !important;
}

#crisp-chatbox {
    z-index: 20 !important;
}

#privy-container .privy-powered-by {
    display: none !important;
    opacity: 0 !important;
}

#privy-container * {
    font-family: 'Rubik', sans-serif !important;
}

.h-32 {
    height: 8rem;
}

#NagishLiTrigger {
    max-width: 30px !important;
    max-height: 30px !important;
    display: inline-block;
    position: fixed;
    bottom: 10px !important;
    left: calc(0px + (100vw - 100%)) !important;
    right: auto !important;
}

.nagishli-trigger-icon {
    padding: 3px !important;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Rubik', sans-serif !important;
    direction: rtl;
    text-align: right;
    overflow-y: auto;
}

.Toastify__toast-container {
    z-index: 999999999990000 !important;
}

.Toastify__toast-container *,
.Toastify__toast *,
.Toastify__toast-body * {
    font-family: 'Rubik', sans-serif !important;
}

a {
    color: inherit;
    text-decoration: none;
}

.direction-rtl {
    direction: rtl !important;
}

.direction-ltr {
    direction: ltr !important
}

input[type=text],
input[type=date],
input[type=email],
input.DatePicker__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

*:not(input):not(textarea) {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

input[type=text],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.responsive-calendar {
    font-size: 11px !important;
}

@media (max-width: 768px) {
    .standard-modal {
        height: 100%;
    }
}

.custom-popup-container {
    width: 100%;
}

@media (min-width: 768px) {
    .standard-modal {
        max-height: 85%;
    }

    .custom-popup-container {
        width: 57%;
    }

    .payment-amount-input {
        max-width: 15%;
    }
}

@media (min-width: 1024px) {
    .custom-popup-container {
        width: 40%;
    }
}

@media (min-width: 1280px) {
    .custom-popup-container {
        width: 33%;
    }
}

@media (max-width: 1500px) {
    .responsive-calendar {
        font-size: 11px !important;
    }
}

@media (max-width: 1200px) {
    .responsive-calendar {
        font-size: 10px !important;
    }
}

@media (max-width: 768px) {
    .responsive-calendar {
        font-size: 10px !important;
    }

    .payment-amount-input {
        max-width: 27%;
    }

    .payment-method-btn {
        min-height: 55px;
    }
}

/* Large screens */
@media (min-width: 2500px) {
    .responsive-calendar {
        font-size: 12px !important;
    }
}

.BookingCalendar .Calendar__day {
    color: rgb(59 130 246) !important;
    background: rgba(59, 130, 246, 0.05);
    font-weight: normal !important;
}

.Calendar__day.-selected {
    color: white !important;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    color: rgb(59 130 246) !important;
}

.Calendar__day.-selected:after {
    background: white !important;
    opacity: 1 !important;
    border-radius: 50%;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):after {
    bottom: 4px !important;
    width: 5px !important;
    height: 5px !important;
    border-radius: 50%;
    font-weight: normal !important;
    opacity: 1 !important;
    background: rgb(59 130 246) !important;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: #e0ebfa !important;
}

.statItem:nth-child(1),
.statItem:nth-child(2) {
    border-bottom: 1px solid rgba(229, 231, 235, 0.8);
}

.statItem:nth-child(odd) {
    border-left: 1px solid rgba(229, 231, 235, 0.8);
}

.inset-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

ul.recharts-default-legend .recharts-legend-item>svg.recharts-surface {
    margin-right: 0 !important;
    margin-left: 5px;
}

ul.recharts-default-legend .recharts-legend-item {
    margin-right: 0;
}

.newsUpdateBody img {
    @apply w-full h-auto border rounded mt-5 p-2 shadow;
}

div>iframe[title*="chat widget"]:first-child {
    bottom: 10px !important;
    right: 10px !important;
}

#react-confirm-alert * {
    direction: rtl;
    text-align: right;
}

.react-confirm-alert-button-group .approve {
    @apply bg-green-500 font-bold text-base text-white hover:opacity-80;
}

.react-confirm-alert-button-group .decline {
    @apply bg-gray-100 text-gray-700 font-medium text-base hover:opacity-80;
}

#marketing-textarea[disabled] {
    cursor: auto;
    border-color: rgb(223, 228, 234);
    background-color: white;
}

@media (max-width: 768px) {
    .advanced-sale-details {
        background-color: #fde68a;
    }
}

.custom-popup {
    background-image: url("../public/img/custom-popup-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.grow {
    flex-grow: 1;
}

.width-fit-content {
    width: fit-content;
}

.copy-to-clipboard-btn {
    min-width: 80px;
}

@media (min-width: 768px) {
    .copy-to-clipboard-btn {
        min-width: 88px;
    }

    .website-main-content-container {
        max-width: 56rem;
    }

    .infinite-app-description {
        font-size: 3rem;
        min-height: 75px;
    }
}

.message-editor {
    word-break: keep-all;
}

.message-editor button {
    background-color: #e5e7eb;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    display: inline;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    height: auto;
    padding: 2px 6px;
    margin: 0 2px;
    line-height: 1.2;
}

.message-editor button:hover {
    background-color: #dbdbdc;
    transition: 0.2s ease;
}

.message-editor span {
    white-space: nowrap;
}

@media (max-width: 768px) {
    .custom-message-param-btn {
        flex-grow: 1;
    }

    .website-main-content-container {
        max-width: 24rem;
    }

    .infinite-app-description {
        font-size: 1.875rem;
        min-height: 90px;
    }
}