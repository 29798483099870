.customCalendar {
    box-shadow: none !important;
}

.customCalendar .Calendar__day {
    color: #898989 !important;
    font-weight: 400 !important;
}

.availabilityCalendar .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):after {
    display: none !important;
}

.DatePicker__calendarContainer,
.DatePicker__calendarContainer .availabilityCalendar {
    width: 16rem !important;
    height: 10px;
}

.availabilityCalendar .Calendar__day {
    width: 1.7rem;
    height: 1.7rem !important;
}

.DatePicker__input {
    @apply shadow-none rounded w-full h-10 border border-gray-200
}

.availabilityCalendar {
    font-size: 10px !important;
    @apply shadow-lg border;
}

.customCalendar .Calendar__day.-selected {
    color: white !important;
}

.customCalendar .Calendar__weekDay {
    color: #393939;
}

.customCalendar .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: #e0ebfa !important;
    color: rgb(59 130 246) !important;
}

.day-with-work,
.day-with-open-groups,
.day-with-closed-groups {
    position: relative;
}

.day-with-work:after,
.day-with-open-groups:after,
.day-with-closed-groups:after {
    bottom: 4px !important;
    width: 5px !important;
    height: 5px !important;
    border-radius: 50%;
    font-weight: normal !important;
    opacity: 1 !important;
    content: '';
    position: absolute;
    display: block;
    left: 52%;
    transform: translateX(-50%);
    transition: 0.2s;
}

.day-with-work:after,
.day-with-open-groups:after {
    background: rgb(91, 205, 144) !important;
}

.day-with-closed-groups:after {
    background: #f87171 !important;
}